// React
import { useMemo } from 'react';

// Api
import useGetQuery from '../api/useGetQuery';
import usePostMutation from '../api/usePostMutation';

// Components
import PageHeader from '../components/common/PageHeader';
import { CheckboxCell } from '../components/tables/TableCell';

// Config
import { GET_LICENCES, POST_LICENCES } from '../config/endpoints';
import { licencesColumns } from '../config/tables/licences';

// Types
import { LicenceHasRole, Licence } from '../types/entities';
import { LicenceUpdateRequest } from '../types/requests';

// Others
import { Table } from '@edalab/react-tables';

/**
 * Licences page
 * @description
 * Editable list of licences and permissions.
 * This list is global and shared between all organizations.
 *
 * Licences defines which MCCS modules are available for
 * this instance and which permission does every operator role have.
 *
 * Only super admins can update licences.
 */
function LicencesPage() {
    // Query
    const licencesQuery = useGetQuery<Licence[]>(['licences'], GET_LICENCES);
    const licencesMutation = usePostMutation<LicenceUpdateRequest>(POST_LICENCES);

    console.log(licencesQuery);

    // Methods
    const onCellPress = (item: LicenceHasRole, key: string) => {
        if (key !== 'name') {
            const newLicences: Licence[] = licences!.map(licence => {
                if (item.name.includes(licence.name)) {
                    if (item.values.includes(key)) {
                        licence.values.splice(licence.values.indexOf(key), 1);
                        return {
                            name: licence.name,
                            enabled: licence.enabled,
                            operatorRoles: licence.values
                        };
                    } else {
                        licence.values.push(key);
                        return {
                            name: licence.name,
                            enabled: licence.enabled,
                            operatorRoles: licence.values
                        };
                    }
                }
                return {
                    name: licence.name,
                    enabled: licence.enabled,
                    operatorRoles: licence.values
                };
            });
            licencesMutation.mutateAsync({
                licences: newLicences
            });
        }
    };

    // Memos
    const licences = useMemo<LicenceHasRole[] | undefined>(() => {
        if (!licencesQuery.data) return;
        return licencesQuery.data.map(licence => {
            return { name: licence.name, enabled: licence.enabled, values: licence.operatorRoles };
        });
    }, [licencesQuery.data]);

    console.log(licences);

    // Render
    return (
        <div>
            {licences && (
                <div>
                    <PageHeader title='permissions:title' />
                    <Table<LicenceHasRole>
                        dataKey='name'
                        checkboxRenderer={(checked, onChange) => (
                            <CheckboxCell checked={checked} onChange={onChange} />
                        )}
                        initialSelection={licences
                            .filter(licence => licence.enabled)
                            .map(licence => licence.name)}
                        onSelectionUpdate={selection => {
                            licences.map(licence => {
                                if (selection.includes(licence.name)) licence.enabled = true;
                                else licence.enabled = false;
                                return null;
                            });
                            licencesMutation.mutateAsync({
                                licences: licences.map(licence => {
                                    return {
                                        name: licence.name,
                                        enabled: licence.enabled,
                                        operatorRoles: licence.values
                                    };
                                })
                            });
                        }}
                        columns={licencesColumns}
                        data={licences}
                        paginationConfig={undefined}
                        onCellPress={onCellPress}
                    />
                </div>
            )}
        </div>
    );
}

export default LicencesPage;
