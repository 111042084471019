// React
import { useMemo, useState } from 'react';

// Api
import useGetQuery from '../api/useGetQuery';

// Pages
import PatientAssessmentsFragment from './fragments/PatientAssessmentsFragment';
import PatientGalleryFragment from './fragments/PatientGalleryFragment';
import PatientTriagesFragment from './fragments/PatientTriagesFragment';

// Components
import DetailsCard from '../components/common/DetailsCard';
import Feed from '../components/common/Feed';
import PdfDownloadButton from '../components/common/PdfDownloadButton';
import PatientDetailsTabBar from '../components/navigation/PatientDetailsTabBar';
import PatientPdf from '../components/pdf/PatientPdf';

// Navigation
import { useParams } from 'react-router-dom';

// Config
import {
    GET_PATIENT,
    GET_PATIENT_ASSESSMENTS,
    GET_PATIENT_IMAGES,
    GET_PATIENT_TRIAGES
} from '../config/endpoints';

// Utils
import { parseUrl } from '../utils/api';

// Types
import { Fas, Patient, Sas, Triage } from '../types/entities';

// Others
import { useLocation } from 'react-router';

const tabs = [
    'patientsDetails:informations',
    'patientsDetails:triages',
    'patientsDetails:assessments',
    'patientsDetails:history',
    'patientsDetails:images'
];

type _PatientDetailsPageProps = {
    /** Patient entity */
    patient: Patient;
};

function _PatientDetailsPage({ patient }: _PatientDetailsPageProps) {
    // State
    const [selectedTab, setSelectedTab] = useState(0);

    // Query
    const triageQuery = useGetQuery<Triage[]>(
        ['patients', 'triages', patient.id],
        parseUrl(GET_PATIENT_TRIAGES, [{ key: 'id', value: patient.id }])
    );
    const assessmentsQuery = useGetQuery<(Fas | Sas)[]>(
        ['patients', 'assessments', patient.id],
        parseUrl(GET_PATIENT_ASSESSMENTS, [{ key: 'id', value: patient.id }])
    );
    const imagesQuery = useGetQuery<string[]>(
        ['patients', 'images', patient.general.code],
        parseUrl(GET_PATIENT_IMAGES, [{ key: 'code', value: patient.general.code }])
    );

    // Memos
    const lastestTriage = useMemo<Triage | undefined>(() => {
        let latest: Triage | undefined = undefined;
        triageQuery.data?.forEach(t => {
            if (!latest) {
                latest = t;
                return;
            } else if (latest.general.startTime < t.general.startTime) latest = t;
        });
        return latest;
    }, [triageQuery.data]);

    const latestFas = useMemo<Fas | undefined>(() => {
        let latest: Fas | undefined = undefined;
        assessmentsQuery.data
            ?.filter(s => s.general.assessmentType === 'fas')
            .forEach(t => {
                if (!latest) {
                    latest = t as Fas;
                    return;
                } else if (latest.general.startTime < t.general.startTime) latest = t as Fas;
            });
        return latest;
    }, [assessmentsQuery.data]);

    const latestSas = useMemo<Sas | undefined>(() => {
        let latest: Sas | undefined = undefined;
        assessmentsQuery.data
            ?.filter(s => s.general.assessmentType === 'sas')
            .forEach(t => {
                if (!latest) {
                    latest = t as Sas;
                    return;
                } else if (latest.general.startTime < t.general.startTime) latest = t as Sas;
            });
        return latest;
    }, [assessmentsQuery.data]);

    const latestNews2 = useMemo<Triage | undefined>(() => {
        let latest: Triage | undefined = undefined;
        triageQuery.data
            ?.filter(s => s.general.triageType === 'NEWS')
            .forEach(t => {
                if (!latest) {
                    latest = t;
                    return;
                } else if (latest.general.startTime < t.general.startTime) latest = t;
            });
        return latest;
    }, [triageQuery.data]);

    const latestVitalSigns = useMemo<Triage | undefined>(() => {
        let latest: Triage | undefined = undefined;
        triageQuery.data
            ?.filter(s => s.general.triageType === 'VITAL_SIGNS')
            .forEach(t => {
                if (!latest) {
                    latest = t;
                    return;
                } else if (latest.general.startTime < t.general.startTime) latest = t;
            });
        return latest;
    }, [triageQuery.data]);

    // Methods
    const renderTab = () => {
        switch (selectedTab) {
            case 0:
                return (
                    <>
                        <div className='w-full bg-red-100'>
                            {/* <PDFViewer style={{ width: '100%', height: 1000 }}>
                                    <PatientPdf
                                        patient={patient}
                                        latestTriage={lastestTriage}
                                        latestFas={latestFas}
                                        latestSas={latestSas}
                                        latestNews2={latestNews2}
                                        latestVitalSigns={latestVitalSigns}
                                        images={imagesQuery.data?.data}
                                    />
                                </PDFViewer> */}
                        </div>
                        <DetailsCard title='General' id='general' informations={patient!.general} />
                        <DetailsCard
                            title='personalInformations:titile'
                            id='personalInformations'
                            informations={patient!.personalInformations}
                        />
                        <DetailsCard title='Status' id='status' informations={patient!.status} />
                    </>
                );
            case 1:
                return <PatientTriagesFragment triages={triageQuery.data} />;
            case 2:
                return <PatientAssessmentsFragment assessments={assessmentsQuery.data} />;
            case 3:
                return (
                    <Feed
                        triages={triageQuery.data}
                        assessments={assessmentsQuery.data}
                        initialPosition={
                            patient.general.createdAt &&
                            patient.general.latitude &&
                            patient.general.longitude
                                ? {
                                      latitude: patient.general.latitude,
                                      longitude: patient.general.longitude,
                                      timestamp: patient.general.createdAt
                                  }
                                : undefined
                        }
                    />
                );
            case 4:
                return (
                    <PatientGalleryFragment
                        patCode={patient!.general.code}
                        images={imagesQuery.data}
                    />
                );
            default:
                return <div></div>;
        }
    };

    // Render
    return (
        <div>
            <PatientDetailsTabBar
                title='patientsDetails:title'
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                tabs={tabs}
                color={patient.general.color}>
                <PdfDownloadButton
                    document={
                        <PatientPdf
                            patient={patient}
                            latestTriage={lastestTriage}
                            latestFas={latestFas}
                            latestSas={latestSas}
                            latestNews2={latestNews2}
                            latestVitalSigns={latestVitalSigns}
                            images={imagesQuery.data}
                        />
                    }
                />
            </PatientDetailsTabBar>
            {renderTab()}
        </div>
    );
}

/**
 * Custom details page for the patient
 * @description
 * The patient details page differs from the other.
 * It has multiple tabs:
 * - informations
 * - triages
 * - assessments
 * - history
 * - images
 * @returns
 */
function PatientDetailsPage() {
    // Hooks
    const { state } = useLocation<Patient | undefined>();
    const { code } = useParams<{ code: string }>();

    // Query
    const patientQuery = useGetQuery<Patient>(
        ['patients', code],
        parseUrl(GET_PATIENT, [{ key: 'code', value: code }])
    );

    // Memos
    const patient = useMemo(() => {
        if (patientQuery.data) return patientQuery.data;
        return state || undefined;
    }, [patientQuery.data]);

    return !!patient ? <_PatientDetailsPage patient={patient} /> : <div />;
}

export default PatientDetailsPage;
