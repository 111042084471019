// React
import { useContext, useEffect, useState } from 'react';

// Components
import LocaleText from './LocaleText';
import { socket, SocketContext } from '../providers/SocketProvider';

// Others
import { RssIcon } from '@heroicons/react/solid';

/**
 * Live updates indicator
 * @description
 * Indicates if the client is connected via websocket to
 * the server.
 *
 * If this is the case, the dashboard will automatically update
 * its content without needing to refresh.
 */
function LiveUpdatesIndicator() {
    // Hooks
    const { getConnectionState } = useContext(SocketContext);

    // State
    const [isLive, setIsLive] = useState(getConnectionState());

    // Effects
    useEffect(() => {
        socket.on('connect', () => {
            setIsLive(true);
        });

        socket.on('disconnect', () => {
            setIsLive(false);
        });

        return () => {
            socket.off('connect');
            socket.off('disconnect');
        };
    }, []);

    // Render
    return (
        <span className='focus:outline-none flex cursor-default items-center space-x-2 text-left sm:text-sm'>
            <RssIcon
                className={`h-4 w-4 ${isLive ? 'text-green-500' : 'text-red-400'}`}
                aria-hidden='true'
            />
            <LocaleText
                className='flex flex-1 items-center justify-between'
                text={isLive ? 'infoBar:liveUpdatesOn' : 'infoBar:liveUpdatesOff'}
            />
        </span>
    );
}

export default LiveUpdatesIndicator;
