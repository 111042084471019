// React
import { useState } from 'react';

// Hooks
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';

// Store
import { setSelectedMission, setSelectedOrganization } from '../../store/slices/statusSlice';

// Api
import useGetMutation from '../../api/useGetMutation';
import useGetQuery from '../../api/useGetQuery';
import { useQueryClient } from 'react-query';

// Components
import NavbarDropdown from './NavbarDropdown';

// Navigation
import { useHistory } from 'react-router-dom';

// Config
import { GET_MISSIONS, GET_ORGANIZATIONS } from '../../config/endpoints';

// Types
import { Mission, Organization } from '../../types/entities';
import { STORAGE_KEY } from '../../types/enums';
import { ROLE } from '../../types/enums';

// Others
import { OfficeBuildingIcon } from '@heroicons/react/outline';

/**
 * Organization selector
 * @description
 * Selects a organization from the available ones. When there is a change,
 * all of the dashboard content updates to show only the
 * data relative to the requested organization.
 *
 * This selector is available only to the admin users.
 */
function OrganizationSelector() {
    // Hooks
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const history = useHistory();

    // State
    const selectedOrganization = useAppSelector(state => state.status.selectedOrganization);
    const [selected, setSelected] = useState<Organization>(selectedOrganization!);

    // Gloabal State
    const { user } = useAppSelector(state => state.authentication);

    // Api
    const organizationQuery = useGetQuery<Organization[]>(['organizations'], GET_ORGANIZATIONS);
    const missionsMutation = useGetMutation<Mission[]>(GET_MISSIONS);

    // Methods
    const changeOrganization = async (organization: Organization) => {
        const currentOrganizationId = localStorage.getItem(STORAGE_KEY.SELECTED_ORGANIZATION);
        localStorage.setItem(STORAGE_KEY.SELECTED_ORGANIZATION, organization.id.toString());

        // Retrieve organization missions
        missionsMutation
            .mutateAsync()
            .then(_missions => {
                const missions = _missions.filter(m => !m.endTime && !m.isArchiving);

                // If the organization doesn't have any mission, display an error
                if (!missions.length) {
                    setSelected(organization);
                    localStorage.removeItem(STORAGE_KEY.SELECTED_MISSION);
                    dispatch(setSelectedOrganization(organization));
                    dispatch(setSelectedMission(null));
                    queryClient.invalidateQueries(['missions']);
                    /* dispatch(setSelectedMission(null));
                    dispatch(setError('noMissionError'));
                     */
                    history.push('/missions');
                } else {
                    localStorage.setItem(STORAGE_KEY.SELECTED_MISSION, missions[0].id.toString());
                    queryClient.invalidateQueries([]);
                    dispatch(setSelectedOrganization(organization));
                    dispatch(setSelectedMission(missions[0]));
                    setSelected(organization);
                }
            })
            .catch(() => {
                currentOrganizationId &&
                    localStorage.setItem(STORAGE_KEY.SELECTED_ORGANIZATION, currentOrganizationId);
            });
    };

    // Render
    return (
        <>
            {user?.role === ROLE.SUPER_ADMIN && (
                <NavbarDropdown
                    items={organizationQuery.data || [selectedOrganization]}
                    selected={selected}
                    setSelected={changeOrganization}
                    icon={OfficeBuildingIcon}
                    label='infoBar:selectedOrganization'
                />
            )}
        </>
    );
}

export default OrganizationSelector;
