// Components
import { BooleanTableCell } from '../../components/tables/TableCell';

// Types
import { LicenceHasRole } from '../../types/entities';
import { OPERATOR_ROLE } from '../../types/enums';

// Others
import { ColumnConfig } from '@edalab/react-tables';

export const licencesColumns: ColumnConfig<LicenceHasRole>[] = [
    { title: 'permissions:permission', key: 'name' },
    ...Object.values(OPERATOR_ROLE).map<ColumnConfig<LicenceHasRole>>(role => {
        return {
            title: `operators:${role}`,
            key: role,
            disableOrderIcon: true,
            renderer: lhs => (
                <BooleanTableCell
                    value={lhs.values.includes(role)}
                    className='cursor-pointer px-1'
                />
            )
        };
    })
];
