// React
import { useMemo } from 'react';

// Config
import { triageColumns } from '../../config/tables/triages';

// Types
import { Triage } from '../../types/entities';

// Others
import { Table } from '@edalab/react-tables';
import { useHistory } from 'react-router';

const detailsHeaders = [{ key: 'details', title: 'Details', subtitle: 'Specific triage details' }];

type PatientTriagesFragmentProps = {
    /** Patient triages */
    triages?: Triage[];
};

/**
 * Ordered list of patient triages
 * @param props
 */
function PatientTriagesFragment(props: PatientTriagesFragmentProps) {
    // Navigation
    const history = useHistory();

    // Memos
    const triages = useMemo(() => {
        return props.triages?.sort((a, b) => (a.general.endTime > b.general.endTime ? -1 : 1));
    }, [props.triages]);

    // Methods
    const navigate = (triage: Triage) => {
        history.push({
            pathname: '/triages/' + triage.id,
            state: {
                details: triage,
                headers: detailsHeaders,
                title: 'Triage Details'
            }
        });
    };

    // Render
    return (
        <div className='mt-10'>
            <Table<Triage>
                columns={triageColumns}
                data={triages}
                baseRowClasses='cursor-pointer'
                onCellPress={item => navigate(item)}
            />
        </div>
    );
}

export default PatientTriagesFragment;
