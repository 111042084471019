// React
import { useMemo, useRef } from 'react';

// Hooks
import useFilter from '../hooks/useFilter';

// Api
import useGetQuery from '../api/useGetQuery';

// Components
import PageHeader from '../components/common/PageHeader';
import TableFilter from '../components/tables/TableFilter';

// Config
import { GET_TRIAGES } from '../config/endpoints';
import { triageColumns } from '../config/tables/triages';

// Types
import { Triage } from '../types/entities';

// Others
import { Table, TableView } from '@edalab/react-tables';
import { useHistory } from 'react-router';

const detailsHeaders = [{ key: 'details', title: 'Details', subtitle: 'Specific triage details' }];

type TriagePageProps = {
    /** Number of table rows to be displayed */
    numberOfRows?: number;
};

/**
 * Triages page
 * @description
 * Ordered and filterable list of triages.
 * Only triages relative to the current selection mission
 * will be shown.
 *
 * The user can access a triage details or remove it.
 */
function TriagePage(props: TriagePageProps) {
    // Hooks
    const history = useHistory();
    const { filter, setFilter, applyFilter } = useFilter(triageColumns, 0, [
        { colKey: 'general.color', locKey: 'general' }
    ]);

    // Reference
    const tableView = useRef<TableView>(null);

    // Query
    const triageQuery = useGetQuery<Triage[]>(['triages'], GET_TRIAGES);

    // Methods
    const navigate = (triage: Triage) => {
        history.push({
            pathname: '/triages/' + triage.id,
            state: {
                details: triage,
                headers: detailsHeaders,
                title: 'triageDetails'
            }
        });
    };

    // Memos
    const triages = useMemo<Triage[] | undefined>(() => {
        if (!triageQuery.data) return;
        let triages = (triageQuery.data || []).sort((a, b) =>
            a.general.endTime > b.general.endTime ? -1 : 1
        );
        triages = applyFilter(triageQuery.data);
        tableView.current?.changePage(1);

        return props.numberOfRows ? triages.slice(0, props.numberOfRows) : triages;
    }, [triageQuery.data, filter]);

    // Render
    return (
        <div>
            <PageHeader title='triages:title' />
            <TableFilter columns={triageColumns} filter={filter} setFilter={setFilter} />
            <Table<Triage>
                ref={tableView}
                columns={triageColumns}
                baseRowClasses='cursor-pointer'
                data={triages}
                onCellPress={item => navigate(item)}
            />
        </div>
    );
}

export default TriagePage;
