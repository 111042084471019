// Locale
import { useTranslation } from 'react-i18next';
import { ENGLISH, ITALIAN, LITHUANIAN, FRENCH, GERMAN, POLISH } from '../../locale/languages';

// Images
import italianFlag from '../../assets/images/flags/it.png';
import ukFlag from '../../assets/images/flags/gb.png';
import germanFlag from '../../assets/images/flags/de.png';
import lithuanianFlag from '../../assets/images/flags/lt.png';
import frenchFlag from '../../assets/images/flags/fr.png';
import polishFlag from '../../assets/images/flags/pl.png';

/**
 * Language selector
 * @description
 * Allows the user to change application language
 */
function LanguageSelector() {
    // Hooks
    const { i18n } = useTranslation();

    // Methods
    const changeLanguage = (lang: string) => {
        sessionStorage.setItem('override-lang', lang);
        i18n.changeLanguage(lang);
    };

    const renderFlag = () => {
        switch (i18n.language) {
            case 'it':
                return (
                    <img
                        src={italianFlag}
                        alt='italian Flag'
                        className='mr-2 h-6 w-9 cursor-pointer'
                        onClick={() => changeLanguage(LITHUANIAN)}
                    />
                );
            // case 'de':
            //     return (
            //         <img
            //             src={germanFlag}
            //             alt='german Flag'
            //             className='mr-2 h-6 w-9 cursor-pointer'
            //             onClick={() => changeLanguage(LITHUANIAN)}
            //         />
            //     );
            case 'lit':
                return (
                    <img
                        src={lithuanianFlag}
                        alt='lithuanian Flag'
                        className='mr-2 h-6 w-9 cursor-pointer'
                        onClick={() => changeLanguage(ENGLISH)}
                    />
                );
            case 'en':
                return (
                    <img
                        src={ukFlag}
                        alt='uk Flag'
                        className='mr-2 h-6 w-9 cursor-pointer'
                        onClick={() => changeLanguage(FRENCH)}
                    />
                );
            case 'fr':
                return (
                    <img
                        src={frenchFlag}
                        alt='french Flag'
                        className='mr-2 h-6 w-9 cursor-pointer'
                        onClick={() => changeLanguage(ITALIAN)}
                    />
                );
            // case 'pol':
            //     return (
            //         <img
            //             src={polishFlag}
            //             alt='polish Flag'
            //             className='mr-2 h-6 w-9 cursor-pointer'
            //             onClick={() => changeLanguage(ITALIAN)}
            //         />
            //     );
            default:
                return (
                    <img
                        src={ukFlag}
                        alt='uk Flag'
                        className='mr-2 h-6 w-9 cursor-pointer'
                        onClick={() => changeLanguage(ITALIAN)}
                    />
                );
        }
    };

    // Render
    return renderFlag();
}

export default LanguageSelector;
