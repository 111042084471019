// Api
import useGetQuery from '../../api/useGetQuery';

// Components
import LocaleText from '../common/LocaleText';

// Config
import { GET_VERSIONS } from '../../config/endpoints';

// Types
import { VersionResponse } from '../../types/responses';

/**
 * Information form
 * @description
 * Shows information about the current versions of
 * all the Maxxie components
 */
function InfoForm() {
    // Api
    const versionsQuery = useGetQuery<VersionResponse>(['versions'], GET_VERSIONS);

    // Render
    return (
        <form>
            <div className='-space-y-px rounded-lg bg-white p-5 shadow-sm'>
                <div className='mb-5 flex items-center space-x-2'>
                    <LocaleText className='text-lg font-bold' text='infoBar:versions' />
                </div>
                <div className='mt-5 space-y-0.5 text-sm'>
                    <div className='flex items-center justify-start space-x-3'>
                        <div className='rounded-full border border-gray-200 py-0.5 px-2 font-medium'>
                            {versionsQuery.data?.dashboard}
                        </div>
                        <div className='font-normal'>Dashboard</div>
                    </div>
                    <div className='flex items-center justify-start space-x-3'>
                        <div className='rounded-full border border-gray-200 py-0.5 px-2 font-medium'>
                            {versionsQuery.data?.dashboardManager}
                        </div>
                        <div className='font-normal'>Dashboard Manager</div>
                    </div>
                    <div className='flex items-center justify-start space-x-3'>
                        <div className='rounded-full border border-gray-200 py-0.5 px-2 font-medium'>
                            {versionsQuery.data?.mobileManager}
                        </div>
                        <div className='font-normal'>Mobile Manager</div>
                    </div>
                    <div className='flex items-center justify-start space-x-3'>
                        <div className='rounded-full border border-gray-200 py-0.5 px-2 font-medium'>
                            {versionsQuery.data?.notificationService}
                        </div>
                        <div className='font-normal'>Notification Service</div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default InfoForm;
